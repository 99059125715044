import React from "react"
import { RichText } from 'prismic-reactjs'
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

const IndexPage = ({ data }) => {
  const doc = data.prismic.allFoyers.edges.slice(0,1).pop().node;
  console.log("doc ", doc.contenu)
  return (
  <Layout>
    <SEO title={RichText.asText(doc.titre_page)} />
    <Image title={RichText.asText(doc.titre_page)}
            text={RichText.render(doc.description_1)} 
            img={doc.image_fondsSharp.childImageSharp.fluid} />
    {doc.contenu.map(contenu => (
      <section class="content-center main-content">
        {RichText.render(contenu.titre)}
        {RichText.render(contenu.description)}
      </section>
    ))
    }
  </Layout>
  )
}

	
export const query = graphql`
{
  prismic {
    allFoyers {
      edges {
        node {
          titre_page
          description_1
          image_fonds
          image_fondsSharp {
            childImageSharp {
              fluid(maxWidth: 1250) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          contenu {
            description
            image
            titre
          }
        }
      }
    }
  }
}
`

export default IndexPage
